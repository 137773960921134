import Vue from "vue";
import Vuex from "vuex";
import * as Sentry from "@sentry/vue";

import Enumerable from "linq";
import { DateTime } from "luxon";

var packageJson = require('../package.json');

Vue.use(Vuex);

import { getJson, getFile, postJson } from "@/utils/dataTransfer.js";
import { profiles as PROFILES } from "@/utils/consts";

const managementApiEndpointUrl = `${process.env.VUE_APP_BASE_API_ADDRESS}`;

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    meetingCode: "",
    authToken: localStorage.getItem("authToken") ?? sessionStorage.getItem("authToken"),
    startingRoute: undefined,
    debugMode: false,
    debugRegistrantId: undefined,
    eventTree: undefined,
    rootFaculty: undefined,
    sponsors: undefined,
    supporters: undefined,
    scheduleData: undefined,
    registrants: undefined,
    survey: undefined,
    claimEntries: undefined,
    meetings: undefined,
    draftMeetings: undefined,
    surveyResults: undefined,
    onDemand: undefined,
    claimOnly: false,
    hideIconsOnMobile: false,
    onDemandOnly: false,
    speakerPortal: false,
    challengeOnly: false,
    materialsOnly: false,
    archived: false,
    minutesBeforeToShowLaunch: 10,
    disallowLaunchInBrowser: true,
    eventDescriptions: undefined,

    challengeScores: undefined,
    challengeQuestions: undefined,
    challengeSponsorImageSrc: undefined,

    discussionChannel: undefined,
    discussions: [],
    eventTimezone: undefined,
    trackingResults: undefined,

    personInfo: undefined,
    profile: 0,
    chat: undefined,
    watchlist: []
  },
  getters: {
    version() {
      return packageJson.version;
    },
    meetingData(state) {
      return state.meetings?.find(i => i.key === state.meetingCode);
    },
    userData(state) {
      return state.scheduleData?.user;
    },
    claimSurveySubmitted(_, getters) {
      return !!(getters.userData?.claimSurveySubmitted);
    },
    isAttending(_, getters) {
      return !!getters.userData?.isAttending;
    },
    isLocalHost() {
      const l = window.location.hostname.toLowerCase();
      return l.includes("localhost");
    },
    activeMeetings(state, getters) {
      const l = window.location.hostname.toLowerCase();
      return state.meetings?.filter(i => i.active).filter(i => {
        if (!i.domains || getters.isLocalHost) return true;
        return i.domains.findIndex(d => l.includes(d)) > -1;
      });
    },
    hasFaculty(_, getters) {
      return !!getters.faculty?.length;
    },
    hasSponsors(state) {
      return !!state.sponsors?.length;
    },
    hasSupporters(state) {
      return !!state.supporters?.length;
    },
    disclosurePolicy(state) {
      return state.scheduleData?.info?.find(
        i =>
          i.title?.toLowerCase() === "disclosure policy" ||
          i.key?.toLowerCase()?.includes("disclosure")
      )?.copy;
    },
    hasDisclosurePolicy(_, getters) {
      return !!getters.disclosurePolicy;
    },
    isSpeaker(_, getters) {
      return !!getters.allEvents?.find(
        i => !!i.speakers?.find(j => j.id == getters.userData?.id)
      );
    },
    canDebug(_, getters) {
      return !!getters.userData?.canDebug;
    },
    surveyAnswers(state) {
      return state.survey?.map(i => {
        const d = {
          id: i.id
        };

        if (i.value && (!Array.isArray(i.value) || i.value.length)) {
          d.value = i.value;
        }
        if (i.promptValue) {
          d.promptValue = i.promptValue;
        }

        return d;
      }).filter(a => a.value || a.promptValue);
    },
    totalClaimedHours(state) {
      return state.claimEntries?.reduce((a, c) => {
        const cf = parseFloat(c.claimedHours);
        if (isNaN(cf)) return a;
        return a + cf;
      }, 0);
    },
    totalClaimedPatientSafetyHours(state) {
      return state.claimEntries?.reduce((a, c) => {
        const cf = parseFloat(c.claimedPatientSafetyHours);
        if (isNaN(cf)) return a;
        return a + cf;
      }, 0);
    },
    claimEntriesClaims(state) {
      return state.claimEntries?.map(i => {
        return {
          id: i.id,
          claimedHours: i.claimedHours,
          claimedPatientSafetyHours: i.claimedPatientSafetyHours
        }
      })
        .filter(i => i.claimedHours !== undefined || i.claimedPatientSafetyHours !== undefined);
    },
    hasSurvey(state) {
      return !!state.survey?.length;
    },
    hasOnDemand(state) {
      return !!state.onDemand;
    },
    hasChallenge(state) {
      return !!state.challengeQuestions?.length;
    },
    hasSpeakerPortal(state) {
      return !!state.speakerPortal;
    },
    allEvents(_, getters) {
      if (!getters.events) return [];
      const flatten = (array) => array.flatMap((i) => [i, ...flatten(i.events || [])])
      return flatten(getters.events);
    },
    bannerAds(state) {
      return state.scheduleData?.scheduleAd?.filter((x) => x.bannerAd);
    },
    menuAds(state) {
      return state.scheduleData?.scheduleAd?.filter((x) => x.menuAd);
    },
    loginAds(_, getters) {
      return getters.meetingData?.loginAds;
    },
    sideBarAds(state) {
      return state.scheduleData?.scheduleAd?.filter((x) => x.sidebarAd);
    },
    generalAds(state) {
      return state.scheduleData?.scheduleAd?.filter((x) => x.generalAd);
    },
    meetingArchived(getters) {
      const meeting = getters.meetingData;
      return meeting?.archived;
    },
    moreInTrack: (_, getters) => (track, sessionId) => {
      return getters.allEvents.filter(i => i.name?.toLowerCase() !== "break" && i.id !== sessionId && i.tags?.find(t => t === track)).sort(() => Math.random() - 0.5).slice(0, 3);
    },
    moreBySpeaker: (_, getters) => (facultyId, sessionId) => {
      return getters.allEvents.filter(i => i.name?.toLowerCase() !== "break" && i.id !== sessionId && i.speakers?.find(s => s.id === facultyId)).sort(() => Math.random() - 0.5).slice(0, 3);
    },
    getSession: (_, getters) => (id) => {
      return getters.allEvents?.find(i => i.id.toString() === id.toString());
    },
    isOralBoardProfile(_, getters) {
      return getters.meetingData?.profile === PROFILES.OralWritternBoard;
    },
    isPSTMProfile(_, getters) {
      return getters.meetingData?.profile === PROFILES.PSTM;
    },
    abstractEvents(_, getters) {
      return getters.allEvents.filter(i => i.meetingType?.toLowerCase().includes("abstract"));
    },
    nonAbstractEvents (_, getters) {
      return getters.events.filter(i => !i.meetingType?.toLowerCase().includes("abstract"));
    },
    allEventsWithChildren(_, getters) {
      return getters.allEvents.filter(i => i.events?.length);
    },
    getParentSession: (_, getters) => (id) => {
      return getters.allEventsWithChildren.find(i => i.events.find(j => j.id.toString() === id.toString()));
    },
    filterTagsToDisplay(state, getters) {
      return state.scheduleData?.filterTags?.filter((i) => {
        return getters.events?.find((e) =>
          e.tags?.find((t) => t === i)
        );
      });
    },
    showLaunchButtonsOnParents (_, getters) {
      return !getters?.events?.some((p) =>
        p.events?.some(
          (c) => c.zoom && c.zoom.meetingNumber !== p.zoom?.meetingNumber
        )
      );
    },
    events(state, getters) {

      if (!getters.isPSTMProfile) return state.eventTree;

      //Special handling for PSTM

      return state.eventTree;
      //return state.cdnEvents;

    },
    allFaculty(_, getters) {
      if (!getters.allEvents.length) return undefined;

      return Enumerable.from(getters.allEvents).selectMany(i => i.speakers)
        .groupBy(i => i.id).select(i => i.first())
        .select(i => ({ ...i, title: "" })).toArray();
    },
    faculty(state, getters) {
      if (!getters.isPSTMProfile) return state.rootFaculty;

      //Return all for PSTM
      return getters.allFaculty;
    },
    getEventDescription: (state) => (item) => {
      if (!item) return undefined;
      if (item.desc || !state.eventDescriptions) return item.desc;
      return state.eventDescriptions[item.id];
    },
    watchlistEvents(state, getters) {
      if (!getters.allEvents || !state.watchlist?.length) return undefined;

      return getters.allEvents
        .filter(i => state.watchlist?.includes(i.id))
        .sort((a, b) => state.watchlist.indexOf(b.id) - state.watchlist.indexOf(a.id));
    }
  },
  mutations: {
    setEventTree(state, events) {
      state.eventTree = events;
    },
    setRootFaculty(state, faculty) {
      state.rootFaculty = faculty;
    },
    setAuthToken(state, token) {
      state.authToken = token;
    },
    setPersonInfo(state, person) {
      state.personInfo = person;
    },
    setMeetingCode(state, code) {
      if (state.meetingCode != code) {
        state.scheduleData = undefined;
        state.startingRoute = undefined;
      }
      state.meetingCode = code;
    },
    setSponsors(state, sponsors) {
      state.sponsors = sponsors;
    },
    setSupporters(state, supporters) {
      state.supporters = supporters;
    },
    setScheduleData(state, data) {
      state.scheduleData = data;
      if (data?.user)
        Sentry.setUser({ id: data.user.id, email: data.user.email });
      else
        Sentry.setUser(null);
    },
    setRegistrants(state, registrants) {
      state.registrants = registrants;
    },
    setProfile(state, profile) {
      state.profile = profile;
    },
    toggleDebugMode(state) {
      state.debugMode = !state.debugMode;
      if (!state.debugMode) state.debugRegistrantId = undefined;
    },
    setDebugRegistrantId(state, registrantId) {
      state.debugRegistrantId = registrantId;
    },
    setSurvey(state, survey) {
      if (survey) {
        survey.forEach(q => {
          if (!q.value) {
            q.value = undefined;
          }
          if (!q.promptValue) {
            q.promptValue = undefined;
          }
        })
      }
      state.survey = survey;
    },
    setSurveyResults(state, results) {
      state.surveyResults = results;
    },
    setClaimEntries(state, claimEntries) {
      if (claimEntries) {
        claimEntries.forEach(q => {
          if (!q.claimedHours) {
            q.claimedHours = 0;
          }
          if (!q.claimedPatientSafetyHours) {
            q.claimedPatientSafetyHours = 0;
          }
        })
      }
      state.claimEntries = claimEntries;
    },
    setUserClaimSurveySubmitted(state, submitted) {
      const ud = { ...state.scheduleData.user };
      ud.claimSurveySubmitted = !!submitted;
      state.scheduleData.user = ud;
    },
    setStartingRoute(state, route) {
      state.startingRoute = route;
    },
    setSurveyQuestion(state, data) {
      state.survey.find(q => q.id === data.id)[data.field] = data.value;
    },
    setOnDemand(state, onDemand) {
      state.onDemand = onDemand;
    },
    setClaimOnly(state, claimOnly) {
      state.claimOnly = claimOnly;
    },
    setSpeakerPortal(state, speakerPortal) {
      state.speakerPortal = speakerPortal;
    },

    setArchived(state, archived) {
      state.archived = archived;
    },
    setHideIconsOnMobile(state, hideIconsOnMobile) {
      state.hideIconsOnMobile = hideIconsOnMobile;
    },
    setOnDemandOnly(state, odOnly) {
      state.onDemandOnly = odOnly;
    },
    setChallengeOnly(state, challengeOnly) {
      state.challengeOnly = challengeOnly;
    },
    setMaterialsOnly(state, materialsOnly) {
      state.materialsOnly = materialsOnly;
    },
    setDisallowLaunchInBrowser(state, shouldDisallow) {
      state.disallowLaunchInBrowser = !!shouldDisallow;
    },
    setChallengeQuestions(state, questions) {
      state.challengeQuestions = questions;
    },
    setChallengeScores(state, scores) {
      state.challengeScores = scores;
    },
    setChallengeSponsorImageSrc(state, imageSrc) {
      state.challengeSponsorImageSrc = imageSrc;
    },
    setMinutesBeforeToShowLaunch(state, minutes) {
      state.minutesBeforeToShowLaunch = minutes;
    },
    setDiscussionChannel(state, channel) {
      state.discussionChannel = channel;
    },
    setDiscussions(state, discussions) {
      state.discussions = discussions;
    },
    setEventTimezone(state, ez) {
      state.eventTimezone = ez;
    },
    setMeetings(state, meetings) {
      state.meetings = meetings;
    },
    setDraftMeetings(state, draftMeetings) {
      state.draftMeetings = draftMeetings;
    },
    setTrackingResults(state, results) {
      state.trackingResults = results;
    },
    setEventDescriptions(state, descriptions) {
      state.eventDescriptions = descriptions;
    },
    setWatchlist(state, watchlist) {
      state.watchlist = watchlist;
    }
  },
  actions: {
    logIn({ commit }, data) {
      const url = `${managementApiEndpointUrl}Auth/login`;
      const requestData = {
        Username: data.username,
        Password: data.password,
        Route: data.route,
        ProductId: data.productId ?? 'admin'
      };
      return new Promise((resolve, reject) => {
        postJson(url, requestData).then(
          d => {
            if (d.NewPasswordRequired) {
              resolve(d);
              return;
            }
            if (!d.Token) {
              reject("No auth token");
              return;
            }
            commit("setAuthToken", d.Token);
            if (data.persist) {
              localStorage.setItem("authToken", d.Token);
              sessionStorage.removeItem("authToken");
            } else {
              localStorage.removeItem("authToken");
              sessionStorage.setItem("authToken", d.Token);
            }
            resolve(d);
          },
          e => reject(e)
        );
      });
    },
    newPassword({ commit }, data) {
      const url = `${managementApiEndpointUrl}Auth/new-password`;
      return new Promise((resolve, reject) => {
        postJson(url, data).then(d => {
          if (d.invalidNewPassword) {
            reject("New password is not valid");
            return;
          }
          if (!d.token) {
            reject("No auth token");
            return;
          }
          commit("setAuthToken", d.token);
          if (data.persist) {
            localStorage.setItem("authToken", d.token);
            sessionStorage.removeItem("authToken");
          } else {
            localStorage.removeItem("authToken");
            sessionStorage.setItem("authToken", d.token);
          }
          resolve(d);
        }, e => reject(e));
      });
    },
    logInWithBadge({ commit }, data) {
      const url = `${managementApiEndpointUrl}Auth/badge-login`;
      const requestData = {
        lastName: data.lastname,
        BadgeNumber: data.badgenumber,
        Route: data.route,
        ProductId: data.productId ?? 'admin'
      };
      return new Promise((resolve, reject) => {
        postJson(url, requestData).then(
          d => {
            if (!d.Token) {
              reject("No auth token");
              return;
            }
            commit("setAuthToken", d.Token);
            if (data.persist) {
              localStorage.setItem("authToken", d.Token);
              sessionStorage.removeItem("authToken");
            } else {
              localStorage.removeItem("authToken");
              sessionStorage.setItem("authToken", d.Token);
            }
            resolve(d);
          },
          e => reject(e)
        );
      });
    },
    getAdminStatus({ state, commit }) {
      const url = `${managementApiEndpointUrl}Auth/get-person`;
      const requestData = { Token: localStorage.token ?? sessionStorage.token };
      return new Promise((resolve, reject) => {
        postJson(url, requestData, state.authToken).then(
          d => {
            if (d == null) {
              
              reject("Not authorized to view page");
            }
            commit("setPersonInfo", d);
            resolve(d);
          }
        )
      });
    },
    logOut({ commit }) {
      localStorage.clear();
      sessionStorage.clear();
      commit("setAuthToken", undefined);
      commit("setEventTree", undefined);
      commit("setRootFaculty", undefined);
      commit("setSponsors", undefined);
      commit("setSupporters", undefined);
      commit("setScheduleData", undefined);
    },
    getListings({ state }) {
      if (state.meetings) return state.meetings;
      const url = `${managementApiEndpointUrl}Events/listing`;
      return new Promise((resolve) => {
        getJson(url).then(d => {
          this.commit("setMeetings", d);
          resolve(d);
        });
      });
    },
    getAdminListing() { // TODO: need to check state if admin stuff is loaded
      const url = `${managementApiEndpointUrl}Events/adminListing`;
      return new Promise((resolve) => {
        getJson(url).then(d => {
          this.commit("setMeetings", d);
          resolve(d);
        });
      });
    },
    getUpdatedListings({ state }, data) {
      if (data.data == null && state.meetings) return state.meetings;
      const url = `${managementApiEndpointUrl}Events/adminListing`;
      return new Promise((resolve, reject) => {
        getJson(url, state.authToken).then(d => {
          this.commit("setMeetings", d);
          resolve(d)
        },
          e => reject(e))
      })
    },
    getDraftListing({ state }, data) {
      if (data.data == null && state.draftMeetings) return state.draftMeetings;
      if (!state.authToken) return;
      const url = `${managementApiEndpointUrl}DraftEvents`;
      return new Promise((resolve, reject) => {
        getJson(url, state.authToken).then(d => {
          this.commit("setDraftMeetings", d);
          resolve(d)
        },
          e => reject(e))
      })
    },
    getData({ commit, state, getters, dispatch }) {
      if (!state.authToken) return;
      const url = `${managementApiEndpointUrl}Events/${getters.meetingData.eventId}/user-all`;

      return new Promise((resolve, reject) => {
        getJson(url, state.authToken).then(
          d => {
            commit("setOnDemand", d.OnDemand);
            commit("setScheduleData", d.ScheduleData);
            commit("setSurvey", d.SurveyEntries);
            commit("setRootFaculty", d.Faculty);
            commit("setRegistrants", d.Registrants);
            commit("setSponsors", d.Sponsors);
            commit("setSupporters", d.Supporters);
            commit("setClaimEntries", d.ClaimEntries);
            commit("setDisallowLaunchInBrowser", d.DisallowLaunchInBrowser);
            commit("setChallengeQuestions", d.challengeQuestions);
            commit("setChallengeScores", d.ChallengeScores);
            commit("setChallengeSponsorImageSrc", d.ChallengeSponsorImageSrc);
            commit("setMinutesBeforeToShowLaunch", d.MinutesBeforeToShowLaunch);
            commit("setDiscussionChannel", d.DiscussionChannel);
            commit("setEventTimezone", d.EventTimezone);
            commit("setClaimOnly", !!d.ClaimOnly);
            commit("setSpeakerPortal", !!d.speakerPortal);
            commit("setHideIconsOnMobile", !!d.hideIconsOnMobile);
            commit("setOnDemandOnly", !!d.OnDemandOnly);
            commit("setChallengeOnly", !!d.ChallengeOnly);
            commit("setArchived", !!d.archived);
            commit("setMaterialsOnly", !!d.MaterialsOnly);
            commit("setProfile", d.profile);

            if (!d.ClaimOnly) {
              setTimeout(() => { dispatch("syncChallenge").catch((e) => console.error(e)); }, 6 * 60 * 1000);
              dispatch("syncDiscussions", true).catch();
            }

            dispatch("temporal/start");

            resolve(d);
          },
          e => reject(e)
        );
      });
    },
    getAnalyticsData({ state, getters }, method) { 
      if (!state.authToken) return;
      return new Promise((resolve, reject) => {
        getFile(`${managementApiEndpointUrl}LoginTracker/eventData/${getters.meetingData.eventId}/${method}`, state.authToken)
        .then(
          d => {
            resolve(d)
          },
          e => reject(e)
        );
      });
    },
    getEvents({ commit, dispatch, state, getters }) {
      if (!state.authToken) return;
      if (getters.isPSTMProfile) {
        dispatch("getCDNEvents");
        dispatch("getCDNEventDescriptions");
        return;
      }
      let url = `${managementApiEndpointUrl}Events/${getters.meetingData.eventId}/Events`;

      if (state.debugRegistrantId) url += "?regId=" + state.debugRegistrantId;

      return new Promise((resolve, reject) => {
        getJson(url, state.authToken).then(
          d => {
            commit("setEventTree", d);

            resolve(d);
            setTimeout(() => {
              dispatch("getCDNEvents");
            }, 5 * 60 * 1000);
          },
          e => { reject(e) });
      });
    },
    getCDNEvents({ commit, dispatch, getters }) {
      if (!getters.isPSTMProfile) return;
      return new Promise((resolve, reject) => {
        getJson(`https://cdn.plasticsurgery.org/mvm/event-data/${getters.meetingData.productId}-tree-no-desc.json`)
          .then(d => {

            commit("setEventTree", d);

            resolve(d);

            setTimeout(() => {
              dispatch("getCDNEvents");
            }, 5 * 60 * 1000);

          }, e => { reject(e) });
      });

    },
    getCDNEventDescriptions({ commit, dispatch, getters }) {

      return new Promise((resolve, reject) => {
        getJson(`https://cdn.plasticsurgery.org/mvm/event-data/${getters.meetingData.productId}-flat-desc.json`)
          .then(d => {

            commit("setEventDescriptions", d);

            resolve(d);

            setTimeout(() => {
              dispatch("getCDNEventDescriptions");
            }, 15 * 60 * 1000);

          }, e => { reject(e) });
      });

    },
    getSurveyResults({ commit, state, getters }) {
      if (!state.authToken) return;

      const url = `${managementApiEndpointUrl}Events/${getters.meetingData.eventId}/surveyResults`;
      return new Promise((resolve, reject) => {
        getJson(url, state.authToken).then(
          d => {
            commit("setSurveyResults", d);

            resolve(d);
          },
          e => reject(e)
        );
      });
    },
    getScheduleData({ commit, state, getters }) {
      if (!state.authToken) return;

      let url = `${managementApiEndpointUrl}Events/${getters.meetingData.eventId}/scheduleData`;
      if (state.debugRegistrantId) url += "?regId=" + state.debugRegistrantId;

      return new Promise((resolve, reject) => {
        getJson(url, state.authToken).then(
          d => {
            commit("setScheduleData", d);
            resolve(d);
          },
          e => {
            reject(e);
          }
        );
      });
    },
    pushSurveyAnswers({ state, getters }) {
      // const url = `${meetingEndpointBaseUrl}survey&eventId=${getters.meetingData.id}`;
      const url = `${managementApiEndpointUrl}Events/${getters.meetingData.eventId}/survey`;
      return new Promise((resolve, reject) => {
        postJson(url, getters.surveyAnswers, state.authToken).then(
          d => resolve(d),
          e => reject(e)
        );
      });
    },
    pushClaimHours({ commit, state, getters }, data) {
      if (!state.authToken) return;
      commit("setClaimEntries", data);

      const url = `${managementApiEndpointUrl}Events/${getters.meetingData.eventId}/claims`;

      return new Promise((resolve, reject) => {
        postJson(url, getters.claimEntriesClaims, state.authToken).then(
          d => resolve(d),
          e => reject(e)
        );
      });
    },
    submitClaimSurvey({ commit, state, getters }) {
      const url = `${managementApiEndpointUrl}Events/${getters.meetingData.eventId}/submitclaimsurvey`;

      const data = { claims: getters.claimEntriesClaims, answers: getters.surveyAnswers };

      return new Promise((resolve, reject) => {
        postJson(url, data, state.authToken).then(
          d => {
            if (d.response === "Success") {
              commit("setUserClaimSurveySubmitted", true);
              resolve(d)
            }
            else
              reject(d)
          },
          e => reject(e)
        );
      });
    },
    submitChallengeAnswer({ commit, state, getters }, data) {
      const url = `${managementApiEndpointUrl}Events/${getters.meetingData.eventId}/submitchallengeanswer`;

      return new Promise((resolve, reject) => {

        postJson(url, data, state.authToken).then(d => {
          if (d.Code) {
            reject(d);
            return;
          }

          commit("setChallengeQuestions", d.questions);
          commit("setChallengeScores", d.scores);

          resolve(d);
        }, e => reject(e));

      });
    },
    resetChallengeAnswers({ commit, state, getters }) {
      const url = `${managementApiEndpointUrl}Events/${getters.meetingData.productId}/resetchallengeanswers`;

      return new Promise((resolve, reject) => {
        postJson(url, "Reset-Challenge", state.authToken).then(d => {
          if (d.Code) {
            reject(d);
            return;
          }

          commit("setChallengeQuestions", d.questions);
          commit("setChallengeScores", d.scores);

          resolve(d);
        }, e => reject(e));

      });
    },
    syncChallenge({ commit, getters, state, dispatch }) {
      if (!state.authToken) return;

      const url = `${managementApiEndpointUrl}Events/${getters.meetingData?.id}/challenge`;

      return new Promise((resolve, reject) => {
        getJson(url, state.authToken).then(
          d => {
            if (d?.questions) {
              commit("setChallengeQuestions", d.questions);
            }
            if (d?.scores) {
              commit("setChallengeScores", d.scores);
            }

            resolve(d);
          },
          e => reject(e)
        );

        setTimeout(() => { dispatch("syncChallenge").catch((e) => console.error(e)); }, 4 * 60 * 1000);
      });
    },
    syncDiscussions({ state, dispatch, commit }, repeat) {
      if (!state.authToken || !state.discussionChannel) return;

      const url = `${managementApiEndpointUrl}Discussion/${state.discussionChannel}/discussions`;

      return new Promise((resolve, reject) => {
        getJson(url, state.authToken).then(
          d => {
            commit("setDiscussions", d);
            resolve(d);
          },
          e => reject(e)
        );

        if (repeat)
          setTimeout(() => { dispatch("syncDiscussions").catch(); }, 3 * 60 * 1000);
      });
    },
    fetchDiscussion({ state }, discussionId) {
      if (!state.authToken || !state.discussionChannel) return;

      const url = `${managementApiEndpointUrl}Discussion/${discussionId}/discussionpost/get`;

      return new Promise((resolve, reject) => {
        getJson(url, state.authToken).then(
          d => {
            resolve(d);
          },
          e => reject(e)
        );
      });
    },
    postNewDiscussion({ state, dispatch }, data) {
      if (!state.authToken || !state.discussionChannel) return;

      const postData = {
        Title: data.title,
        Message: data.body,
        Channel: state.discussionChannel
      };

      const url = `${managementApiEndpointUrl}Discussion/discussionnewtopic`;
      return new Promise((resolve, reject) => {

        postJson(url, postData, state.authToken).then(d => {

          dispatch("syncDiscussions", false).catch();
          resolve(d);
        }, e => reject(e));

      });
    },
    postReplyDiscussion({ state, dispatch }, data) {
      if (!state.authToken || !state.discussionChannel) return;

      const postData = {
        TopicId: data.id,
        Message: data.body
      };

      const url = `${managementApiEndpointUrl}Discussion/discussionreplytopic`;
      return new Promise((resolve, reject) => {

        postJson(url, postData, state.authToken).then(d => {

          dispatch("syncDiscussions", false).catch();
          resolve(d);
        }, e => reject(e));

      });
    },
    postDataToManagementApi({ state }, data) {
      if (!state.authToken) return;
      const postData = {
        EventId: data.EventId,
        Data: data
      };
      const url = `${managementApiEndpointUrl}Events`;
      return new Promise((resolve, reject) => {
        postJson(url, postData.Data, state.authToken).then(
          d => {
            resolve(d);
          },
          e => reject(e));
      });
    },
    postDraftDataToManagementApi({ state }, data) {
      if (!state.authToken) return;
      const postData = {
        EventId: data.EventId,
        NewEvent: data.IsNew,
        Data: data
      };
      const url = `${managementApiEndpointUrl}DraftEvents`;
      return new Promise((resolve, reject) => {
        postJson(url, postData.Data.data, state.authToken).then(
          d => { resolve(d); },
          e => reject(e));
      });
    },
    trackLoginActivity({ state, getters }, data) {
      const loggingUrl = `${managementApiEndpointUrl}LoginTracker/log`;
      const requestData = {
        EventId: `${getters.meetingData?.eventId}`,
        RequestedUri: `${data?.path}`
      };
      return new Promise((resolve, reject) => {
        postJson(loggingUrl, requestData, state.authToken).then(
          d => { resolve(d); },
          e => reject(e));
      });
    },
    logVideoViews({ state, getters }, data) {
      const loggingUrl = `${managementApiEndpointUrl}LoginTracker/log`;
      const requestData = {
        EventId: `${getters.meetingData?.eventId}`,
        RequestedUri: data?.title,
        ResourceType: "Video"
      };
      return new Promise((resolve, reject) => {
        postJson(loggingUrl, requestData, state.authToken).then(
          d => { resolve(d); },
          e => reject(e));
      });
    },
    // getTrackingResults({ commit, state }) {
    //   const loggingUrl = `${managementApiEndpointUrl}LoginTracker`;
    //   return new Promise((resolve, reject) => {
    //     getJson(loggingUrl, state.authToken).then(
    //       d => {
    //         commit("setTrackingResults", d),
    //           resolve(d);
    //       },
    //       e => reject(e));
    //   });
    // },
    getDisclosures(_, pids) {
      //TODO: Move this to the MVM API
      const url = `https://www1.plasticsurgery.org/services/virtualmeeting.aspx?m=Disclosures&pid=` + pids;
      return new Promise((resolve, reject) => {
        getJson(url).then(
          d => resolve(d),
          e => reject(e));
      });
    },
    getWatchlistFromLocalStorage({ getters, commit }) {
      const eventId = getters.meetingData.eventId;
      const watchlist = localStorage.getItem("watchlist-" + eventId);

      if (watchlist)
        commit("setWatchlist", JSON.parse(watchlist));
    },
    toggleWatchlist({ state, commit, getters }, id) {
      const eventId = getters.meetingData.eventId;
      let ids = [...(state.watchlist ?? [])];

      if (ids.includes(id)) {
        ids = ids.filter(i => i !== id);
      } else {
        ids.push(id);
      }

      commit("setWatchlist", ids);
      localStorage.setItem("watchlist-" + eventId, JSON.stringify(ids));
    },
    clearWatchlist({ getters, commit }) {
      commit("setWatchlist", undefined);
      localStorage.removeItem("watchlist-" + getters.meetingData.eventId);
    },
    getChatBySessionNumber({ state, getters }, data) {
      const url = `${managementApiEndpointUrl}chat/${getters.meetingData?.productId}/${data.sessionId}`;
      return new Promise((resolve, reject) => {
        getJson(url, state.authToken).then(
          d => resolve(d),
          e => reject(e)
        )
      })
    },
    getUpdatedChatBySessionNumber({ state, getters, dispatch }, data) {

      if (!data.lastRefresh)
        return dispatch("getChatBySessionNumber", data);

      const productId = parseInt(getters.meetingData?.productId);
      const url = `${managementApiEndpointUrl}chat/${productId}/${data.sessionId}/since/${data.lastRefresh}`;

      return new Promise((resolve, reject) => {
        getJson(url, state.authToken).then(
          d => resolve(d),
          e => reject(e)
        )
      });
    },
    getExportableChatBySessionNumber({ state }, data) {
      const url = `${managementApiEndpointUrl}chat/${data?.eventId ?? 0}/export/${data?.sessionId ?? 0}`

      return new Promise((resolve, reject) => {
        getJson(url, state.authToken).then(
          d => resolve(d),
          e => reject(e)
        )
      })
    },
    postChatBySessionNumber({ state, getters }, data) {
      const url = `${managementApiEndpointUrl}chat`;
      const requestData = {
        Message: data.message,
        SessionId: data.sessionId,
        Headshot: data.headshot,
        EventId: parseInt(getters.meetingData?.productId)
      }
      return new Promise((resolve, reject) => {
        postJson(url, requestData, state.authToken).then(
          d => resolve(d),
          e => reject(e)
        )
      })
    },
    deletePostBySessionNumber({ state }, data) {
      const url = `${managementApiEndpointUrl}chat/adminmarkfordelete`
      const requestData = data
      return new Promise((resolve, reject) => {
        postJson(url, requestData, state.authToken).then(
          d => resolve(d),
          e => reject(e)
        )
      })
    },
    overwritePostBySessionNumber({ state }, data) {
      const url = `${managementApiEndpointUrl}chat/adminreplace`
      const requestData = data
      return new Promise((resolve, reject) => {
        postJson(url, requestData, state.authToken).then(
          d => resolve(d),
          e => reject(e)
        )
      })
    },
    blockUserById({ state, getters }, data) {
      const url = `${managementApiEndpointUrl}chat/admindisableuserchat`;
      const requestData = {
        UserId: data.UserId,
        EventId: parseInt(getters.meetingData?.productId)
      }
      return new Promise((resolve, reject) => {
        postJson(url, requestData, state.authToken).then(
          d => resolve(d),
          e => reject(e)
        )
      })
    },
    unblockUserById({ state, getters }, data) {
      const url = `${managementApiEndpointUrl}chat/adminenableuserchat`;
      const requestData = {
        UserId: data.UserId,
        EventId: parseInt(getters.meetingData?.productId)
      }
      return new Promise((resolve, reject) => {
        postJson(url, requestData, state.authToken).then(
          d => resolve(d),
          e => reject(e)
        )
      })
    },
    getBlockedUsersByEventId({ state, getters }) {
      const url = `${managementApiEndpointUrl}chat/adminexportblocked/${getters.meetingData?.productId}`;
      return new Promise((resolve, reject) => {
        getJson(url, state.authToken).then(
          d => resolve(d),
          e => reject(e)
        )
      })
    }
  },
  modules: {
    temporal: {
      namespaced: true,
      state: () => ({
        nowDate: DateTime.now(),
        simulatingDate: undefined
      }),
      mutations: {
        setNowDate(state) {
          state.nowDate = DateTime.now();
        },
        setSimulatingDate(state, date) {
          state.simulatingDate = DateTime.fromFormat(date, 'yyyy-MM-dd\'T\'HH:mm');
        },
      },
      actions: {
        start({ commit, state }) {
          commit("setNowDate");

          setTimeout(() => {
            if (state.simulatingDate) return;

            commit("setNowDate");

            setInterval(() => {
              commit("setNowDate");
            }, 60 * 1000);
          }, (60 - state.nowDate.second) * 1000);
        }
      },
      getters: {
        date(state) {
          if (state.simulatingDate) return state.simulatingDate;
          return state.nowDate;
        }
      }
    }
  }
});
