import Vue from "vue";

import {
    LayoutPlugin,
    LinkPlugin,
    ImagePlugin,
    ButtonPlugin,
    InputGroupPlugin,
    FormInputPlugin,
    ListGroupPlugin,
    FormCheckboxPlugin,
    BadgePlugin,
    AlertPlugin,
    FormSelectPlugin,
    CollapsePlugin,
    FormGroupPlugin,
    DropdownPlugin,
    ModalPlugin,
    EmbedPlugin,
    CardPlugin,
    TableSimplePlugin,
    TablePlugin,
    FormPlugin,
    FormTextareaPlugin,
    FormRadioPlugin,
    PopoverPlugin,
    FormTagsPlugin,
    ToastPlugin,
    TooltipPlugin,
    CarouselPlugin,
    PaginationPlugin,
    TabsPlugin,

    BIcon,
    BIconPersonFill,
    BIconPlusCircleFill,
    BIconGripVertical,
    BIconPlus,
    BIconTrash,
    BIconChevronRight,
    BIconGear,
    BIconDice3Fill,
    BIconSearch,
    BIconPlusCircle,
    BIconReplyFill,
    BIconPlayBtn,
    BIconChevronDown,
    BIconDisplay,
    BIconArrowRightCircle,
    BIconCaretRightFill,
    BIconCheck,
    BIconFolder2,
    BIconArrowRight,
    BIconLink45deg,
    BIconArrowUpRightSquare,
    BIconPrinter,
    BIconArrowLeftCircleFill,
    BIconCollection,
    BIconFront,
    BIconQuestionCircle,
    BIconCloudArrowUp,
    BIconCloudArrowUpFill,
    BIconBasketFill,
    BIconDownload,
    BIconCaretRight,
    BIconClockHistory,
    BIconList,
    BIconCheckCircle,
    BIconArrowRightCircleFill,
    BIconBox,
    BIconClock,
    BIconMic,
    BIconCardList,
    BIconPlayCircle,
    BIconStar,
    BIconAsterisk,
    BIconPeople,
    BIconPatchQuestion,
    BIconChatLeftDots,
    BIconCardHeading,
    BIconArrowUpRight,
    BIconBoxArrowUpRight,
    BIconArrowClockwise,
    BIconMicFill,
    BIconArrowDown,
    BIconArrowDownCircleFill,
    BIconHouseDoor,
    BIconExclamationCircleFill,
    BIconBookmarkPlus,
    BIconBookmarkDash,
    BIconBookmark
} from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(LayoutPlugin);
Vue.use(LinkPlugin);
Vue.use(ImagePlugin);
Vue.use(ButtonPlugin);
Vue.use(InputGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormPlugin);
Vue.use(CollapsePlugin);
Vue.use(ListGroupPlugin);
Vue.use(BadgePlugin);
Vue.use(AlertPlugin);
Vue.use(DropdownPlugin);
Vue.use(ModalPlugin);
Vue.use(EmbedPlugin);
Vue.use(CardPlugin);
Vue.use(TableSimplePlugin);
Vue.use(TablePlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormRadioPlugin);
Vue.use(PopoverPlugin);
Vue.use(FormTagsPlugin);
Vue.use(ToastPlugin);
Vue.use(TooltipPlugin);
Vue.use(CarouselPlugin);
Vue.use(PaginationPlugin);
Vue.use(TabsPlugin);

Vue.component("BIcon", BIcon);
Vue.component("BIconPersonFill", BIconPersonFill);
Vue.component("BIconPlusCircleFill", BIconPlusCircleFill);
Vue.component("BIconGripVertical", BIconGripVertical);
Vue.component("BIconPlus", BIconPlus);
Vue.component("BIconTrash", BIconTrash);
Vue.component("BIconChevronRight", BIconChevronRight);
Vue.component("BIconGear", BIconGear);
Vue.component("BIconDice3Fill", BIconDice3Fill);
Vue.component("BIconSearch", BIconSearch);
Vue.component("BIconPlusCircle", BIconPlusCircle);
Vue.component("BIconReplyFill", BIconReplyFill);
Vue.component("BIconPlayBtn", BIconPlayBtn);
Vue.component("BIconChevronDown", BIconChevronDown);
Vue.component("BIconDisplay", BIconDisplay);
Vue.component("BIconArrowRightCircle", BIconArrowRightCircle);
Vue.component("BIconCaretRightFill", BIconCaretRightFill);
Vue.component("BIconCheck", BIconCheck);
Vue.component("BIconFolder2", BIconFolder2);
Vue.component("BIconArrowRight", BIconArrowRight);
Vue.component("BIconLink45deg", BIconLink45deg);
Vue.component("BIconArrowUpRightSquare", BIconArrowUpRightSquare);
Vue.component("BIconPrinter", BIconPrinter);
Vue.component("BIconArrowLeftCircleFill", BIconArrowLeftCircleFill);
Vue.component("BIconCollection", BIconCollection);
Vue.component("BIconFront", BIconFront);
Vue.component("BIconQuestionCircle", BIconQuestionCircle);
Vue.component("BIconCloudArrowUp", BIconCloudArrowUp);
Vue.component("BIconCloudArrowUpFill", BIconCloudArrowUpFill);
Vue.component("BIconBasketFill", BIconBasketFill);
Vue.component("BIconDownload", BIconDownload);
Vue.component("BIconCaretRight", BIconCaretRight);
Vue.component("BIconClockHistory", BIconClockHistory);
Vue.component("BIconList", BIconList);
Vue.component("BIconCheckCircle", BIconCheckCircle);
Vue.component("BIconArrowRightCircleFill", BIconArrowRightCircleFill);
Vue.component("BIconBox", BIconBox);
Vue.component("BIconClock", BIconClock);
Vue.component("BIconMic", BIconMic);
Vue.component("BIconCardList", BIconCardList);
Vue.component("BIconPlayCircle", BIconPlayCircle);
Vue.component("BIconStar", BIconStar);
Vue.component("BIconAsterisk", BIconAsterisk);
Vue.component("BIconPeople", BIconPeople);
Vue.component("BIconPatchQuestion", BIconPatchQuestion);
Vue.component("BIconChatLeftDots", BIconChatLeftDots);
Vue.component("BIconCardHeading", BIconCardHeading);
Vue.component("BIconArrowUpRight", BIconArrowUpRight);
Vue.component("BIconBoxArrowUpRight", BIconBoxArrowUpRight);
Vue.component("BIconArrowClockwise", BIconArrowClockwise);
Vue.component("BIconMicFill", BIconMicFill);
Vue.component("BIconArrowDown", BIconArrowDown);
Vue.component("BIconArrowDownCircleFill", BIconArrowDownCircleFill);
Vue.component("BIconHouseDoor", BIconHouseDoor);
Vue.component("BIconExclamationCircleFill", BIconExclamationCircleFill);
Vue.component("BIconBookmarkPlus", BIconBookmarkPlus);
Vue.component("BIconBookmarkDash", BIconBookmarkDash);
Vue.component("BIconBookmark", BIconBookmark);