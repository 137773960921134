import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/vue-meta";
import "./plugins/bootstrap-vue";
import "./plugins/gtag";
import "./plugins/observe-visibility";
import App from "./App.vue";
import router from "./router.js";
import store from "./store.js";
import * as Sentry from "@sentry/vue";
import styleHandler from './stylehandler.js'

const envName = (process.env.VUE_APP_ENV_NAME ?? "default.local").toLowerCase();

if (!envName.includes("local")) {
  Sentry.init({
    Vue,
    dsn: "https://566d05f8e6344a31a306f0ad51412aa4@o555442.ingest.sentry.io/5685224",
    integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        })
    ],
    tracesSampleRate: .2,
    logErrors: true,
    release: "mvm@" + store.getters.version,
    environment: envName ?? "unknown",
    debug: envName !== "production",
    autoSessionTracking: true,
    sendDefaultPii: true,
    trackComponents: true,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "NavigationDuplicated"
    ],
    denyUrls: [
      /addevent\.com/i
    ]
  });
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  styleHandler,
  render: h => h(App)
}).$mount("#app");
