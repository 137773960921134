const doFetch = async (url, token, init = {}) => {
  const { headers = {}, method = "GET" } = init;

  if (!headers.Authorization && token) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (!headers["content-type"]) {
    headers["content-type"] = "application/json";
  }

  const response = await fetch(url, { ...init, headers, method });

  const responseJson = await response.json();

  if (!response.ok) {
    throw responseJson;
  }

  return responseJson;
};

const fileFetch = async (url, token, init = {}) => {
  const { headers = {}, method = "GET" } = init;

  if (!headers.Authorization && token) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (!headers["content-type"]) {
    headers["content-type"] = "text/csv";
  }

  const response = await fetch(url, { ...init, headers, method });

  const responseJson = await response;

  if (!response.ok) {
    throw responseJson;
  }

  return responseJson;
};

export const getJson = doFetch;
export const getFile = fileFetch;

export const postJson = (url, requestBodyData, token) => {
  const requestData = {
    method: "POST",
    body: JSON.stringify(requestBodyData)
  };
  return doFetch(url, token, requestData);
};