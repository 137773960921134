<template>
  <div>
    <template v-if="hasMeetingData">
      
      <app-admin-bar v-if="!hideChrome && $store.getters.canDebug && !hideAdminBar" @hide="hideAdminBar = true" />

      <div id="container">
        <div id="app">
          
          <template v-if="!hideChrome">
            <div class="env d-print-none" v-if="!!envName">
              <div class="bar">
                {{ envName }}
              </div>
            </div>

            <b-img v-if="!!logoSrc" :src="logoSrc" class="logo" block alt="Event Logo" />
            <h1 v-else class="text-center">
              <small>{{ $store.getters.meetingData.subName }}</small>
              {{ $store.getters.meetingData.name }}
            </h1>

            <app-nav v-observe-visibility="primaryNavVisibilityChanged" />
          </template>
          <div id="content" :class="{ 'hide-chrome' : hideChrome }">
            <router-view />
          </div>
        </div>
        <div id="footer" v-if="!hideChrome" class="pt-4 px-2" v-observe-visibility="footerVisibilityChanged">
          <b-row align-v="center">
            <b-col
              cols="12"
              :lg="isMeetingLoaded ? 6 : 12"
              :class="isMeetingLoaded ? '' : 'text-center'"
            >
              <p class="mb-0 copyright" @click="versionClick">
                <small class="text-light">
                  <strong>ASPS Virtual Meeting Platform</strong><br>
                  <small class="version">version {{ version }}<br>
                  Copyright &copy; 2020-{{currentYear }} {{ copyright }}
                </small>
              </small>
              </p>
            </b-col>
            <b-col
              v-if="isMeetingLoaded"
              cols="12"
              lg="6"
              class="text-lg-right d-print-none"
            >

              <b-button
                class="mt-2 mt-lg-0"
                variant="outline-light"
                size="sm"
                :to="'/' + meetingCode + '/logout'"
              >
                Log Out
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
      <app-nav v-if="showFloatingNav" floating />
    </template>
    <template v-else>
      <div id="container">
        <router-view />
        <div class="text-dark text-center copyright" @click="versionClick">
          <small>
              <strong>ASPS Virtual Meeting Platform</strong><br>
              <small class="version">version {{ version }}<br>
              Copyright &copy; 2020-{{currentYear }} {{ copyright }}

                <div v-if="$store.state.debugMode">
                  Event Id: {{ $store.getters.meetingData.productId }}
                </div>
            </small>
          </small>
        </div>
      </div>
    </template>

    <b-modal
      id="about-modal"
      hide-header
      hide-footer
      centered
      no-fade
      body-class="text-center"
      size="xl"
      body-text-variant="light"
    >
      <div class="d-flex align-items-center">
        <div class="p-3">
          <h3><strong>ASPS Virtual Meeting Platform</strong></h3>
          <h5 class="mb-4">version {{ version }}</h5>
        </div>
          <on-demand-video :data="{ src: 'https://www.youtube.com/embed/aQUlA8Hcv4s?autoplay=1', type: 'iframe'}" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import AppNav from "@/components/AppNav";

const OnDemandVideo = () => import("./components/OnDemandVideo.vue");
const AppAdminBar = () => import("./components/AppAdminBar.vue");

export default {
  name: "App",
  components: { AppNav, OnDemandVideo, AppAdminBar },
  computed: {
    envName() {
      const envName = process.env.VUE_APP_ENV_NAME;
      return envName === "production" ? "" : envName;
    },
    currentYear() {
      return new Date().getFullYear();
    },
    copyright() {
      const l = window.location.hostname.toLowerCase();
      if (l.includes("vasps.org")) {
        return "Virginia Society of Plastic Surgery";
      } else if (l.includes("mwsps.org")) {
        return "Mountain West Society of Plastic Surgeons";
      }
      return "American Society of Plastic Surgeons";
    },
    hasMeetingData() {
      return !!this.$store.getters.meetingData;
    },
    logoSrc() {
      return this.$store.getters.meetingData.logoSrc;
    },
    meetingCode() {
      return this.$store.state.meetingCode;
    },
    isMeetingLoaded() {
      return this.$store.state.authToken;
    },
    version() {
      return this.$store.getters.version;
    },
    showFloatingNav() {
      return !(this.primaryNavIsVisible || this.footerIsVisible);
    },
    hideChrome() {
      return !!this.$route.query["hide-chrome"];
    }
  },
  head: {
    link: [
      {
        rel: "stylesheet",
        href: `${process.env.VUE_APP_BASE_API_ADDRESS}Theming`,
      },
    ],
  },
  methods: {
    versionClick() {
      this.versionClickCount += 1;
      if (this.versionClickCount === 10) {
        this.$bvModal.show("about-modal");
        this.versionClickCount = 0;
      }
    },
    primaryNavVisibilityChanged(e) {
      this.primaryNavIsVisible = e;
    },
    footerVisibilityChanged(e) {
      this.footerIsVisible = e;
    },
  },
  data() {
    return {
      versionClickCount: 0,
      primaryNavIsVisible: true,
      footerIsVisible: false,
      hideAdminBar: false
    };
  },
  metaInfo() {
    return {
      meta: [
        {
          vmid: "theme-color",
          name: "theme-color",
          content:
            this.$store.getters.meetingData?.theme["primary-color"] ?? null,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
$animationDuration: 0.3s;
@import "~vue2-animate/src/sass/vue2-animate.scss";

@import "@/sass/global";
@import "@/sass/tracks";

#about-modal .modal-body {
  background-color: color(display-p3 0.294 0.725 1 / 1) !important;
  padding: 1px;
}

#content.hide-chrome {
  padding: 5px;

  .card {
    border: none;
  }
}
</style>
