import Vue from "vue";
import VueHead from "vue-head";

/// This is used to dynamically update the built stylesheets as they change
/// It could also be used to add more style sheets as need
/// This also could be extended to 
/// inject more head elements
/// specifically meta tags

Vue.use(VueHead);

export default {
  data() {
    return {
      link: [
        {
          rel: 'stylesheet',
          name: 'ApiTheme',
          href: `${process.env.VUE_APP_BASE_API_ADDRESS}Theming`
        }
      ]
    }
  },
  head: {
    link() {
    },
  }
}