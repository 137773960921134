<template>
  <div id="nav" class="d-print-none" v-if="!floating">
    <transition-group name="zoom">
      <span v-for="i in navItems" :key="i.title">
        <b-link v-if="i.to != undefined" :to="'/' + meetingCode + '/' + i.to">
          <b-icon :icon="i.icon" :class="{'hide-on-mobile': hideOnMobile}"/>
          {{ i.title }}
        </b-link>
        <b-link v-else :href="i.href" target="_blank">
          <b-icon :icon="i.icon" :class="{'hide-on-mobile': hideOnMobile}"/>
          {{ i.title }}
        </b-link>
      </span>
    </transition-group>
  </div>

  <div id="float-nav" v-else>
    <div>
      <b-link v-for="i in floatingNavItems" :to="'/' + meetingCode + '/' + i.to" :class="{ secondary: !i.primary }"  :key="i.title">
        <b-icon :icon="i.icon" :class="{'hide-on-mobile': hideOnMobile}"/>
        {{ i.title }}
      </b-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppNav",
  props: {
    floating: Boolean,
  },
  computed: {
    navItems() {
      const items = [
        {
          to: "claim",
          title: "Credit Claim",
          icon: "plus-circle",
          visible: this.showClaim,
          primary: true,
        },
        {
          to: "materials",
          title: "Materials",
          icon: "box",
          visible: this.showMaterials,
          primary: true,
        },
        {
          to: "schedule",
          title: this.$store.getters.isPSTMProfile ? "Home" : "Schedule",
          icon: this.$store.getters.isPSTMProfile ? "house-door" : "clock",
          visible: this.showSchedule,
          primary: true,
        },
        {
          to: "sessions",
          title: "Sessions",
          icon: "mic",
          visible: this.showSessions,
          primary: true
        },
        {
          to: "abstracts",
          title: "Abstracts",
          icon: "card-list",
          visible: this.showAbstracts,
          primary: true
        },
        {
          to: "watchlist",
          title: "Watchlist",
          icon : "bookmark",
          visible: this.showWatchlist,
          primary: false
        },
        {
          to: "on-demand",
          title: "On-Demand",
          icon: "play-circle",
          visible: this.showOnDemand,
          primary: true,
        },
        {
          to: this.hasMultipleSponsors ? "sponsors" : "sponsor",
          title: this.hasMultipleSponsors ? "Sponsors" : "Sponsor",
          icon: "star",
          visible: this.showSponsors,
          primary: true,
        },
        {
          to: "supporters",
          title: "Supporters",
          icon: "asterisk",
          visible: this.showSupporters,
        },
        {
          to: "faculty",
          title: "Faculty",
          icon: "people",
          visible: this.showFaculty,
          primary: true,
        },
        {
          to: "challenge",
          title: "Challenge",
          icon: "patch-question",
          visible: this.showChallenge,
        },
        {
          to: "discussions",
          title: "Discussions",
          icon: "chat-left-dots",
          visible: this.showDiscussions,
        },
        {
          href: "https://plasticsurgery.org",
          title: "Speaker Portal",
          icon: "card-heading",
          visible: this.showSpeakerPortal
        },
        {
          href: this.configureDisplayedAd?.targetUrl,
          title: this.configureDisplayedAd?.title,
          icon: "box-arrow-up-right",
          visible: this.hasNavBarAds,
        }
      ];
      return items.filter((i) => i.visible);
    },
    floatingNavItems() {
      return this.navItems.filter(x => x.to !== undefined);
    },
    meetingCode() {
      return this.$store.state.meetingCode;
    },
    isAttending() {
      return this.$store.getters.isAttending && this.$store.getters.userData?.access !== 0;
    },
    showClaim() {
      return this.isAttending && this.$store.state.claimOnly;
    },
    showSchedule() {
      return (this.isAttending &&
        !this.$store.state.claimOnly &&
        !this.$store.state.onDemandOnly &&
        !this.$store.state.challengeOnly &&
        !this.$store.state.materialsOnly);
    },
    showAbstracts() {
      return this.isAttending && this.$store.getters.isPSTMProfile;
    },  
    showWatchlist() {
      return this.isAttending && this.$store.state.watchlist?.length;
    },  
    showSessions() {
      return this.isAttending && this.$store.getters.isPSTMProfile;
    },
    showSponsors() {
      return this.isAttending && this.$store.getters.hasSponsors;
    },
    hasMultipleSponsors() {
      return (this.$store.state.sponsors?.length ?? 0) > 1;
    },
    showSupporters() {
      return this.isAttending && this.$store.getters.hasSupporters;
    },
    showFaculty() {
      return this.isAttending && this.$store.getters.hasFaculty;
    },
    showOnDemand() {
      return this.isAttending && this.$store.getters.hasOnDemand;
    },
    showChallenge() {
      return (this.isAttending &&
        this.$store.getters.hasChallenge &&
        !this.$store.state.challengeOnly);
    },
    showDiscussions() {
      return this.isAttending && !!this.$store.state.discussionChannel;
    },
    showMaterials() {
      return this.isAttending && this.$store.state.materialsOnly;
    },
    showSpeakerPortal() {
      return this.isAttending && this.$store.getters.hasSpeakerPortal;
    },
    hasNavBarAds() {
      return this.isAttending && !!this.getNavBarAds?.length;
    },
    getNavBarAds() {
      return this.$store.getters.menuAds;
    },
    configureDisplayedAd() {
      const arrLen = this.getNavBarAds?.length;
      if (!arrLen) return undefined;
      // simple random item based on index
      // since this method can only serve one at a time
      const min = Math.ceil(0);
      const max = Math.floor(arrLen);
      const index = Math.floor(Math.random() * (max - min) + min);
      return this.getNavBarAds[index];
    },
    hideOnMobile() {
      return this.$store.state.hideIconsOnMobile;
    },
  },
};
</script>

<style scoped lang="scss">
#nav {
  margin: 15px 0 30px 0;
  text-align: center;

  a {
    font-weight: bold;
    font-size: 0.35rem;
    display: inline-block;
    border-radius: 10px;
    text-decoration: none;
    padding: 6px 5px;
    text-align: center;
    transition: all 0.1s;

    &:hover {
      transition: all 0.2s;
    }

    &.disabled {
      display: none;
    }

    &.router-link-active {
      color: white;
      box-shadow: 1px 1px 4px rgba(black, 20%);
    }

    .b-icon {
      font-size: 2.8em;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 4px;
    }
    .hide-on-mobile {
        display: none;
      }

    @media (min-width: 360px) {
      font-size: 0.65rem;
      padding: 10px 7px;

      .b-icon {
        font-size: 2.1em;
      }
    }

    @media (min-width: 525px) {
      font-size: 0.9rem;
      padding: 12px 10px;
      min-width: 70px;
    }

    @media (min-width: 768px) {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
}

#float-nav {
  position: fixed;
  bottom: 60px;
  z-index: 1020;
  text-align: center;
  left: 0;
  right: 0;
  height: 0;

  >div {
    background: white;
    display: inline-block;
    box-shadow: 2px 2px 10px rgba(black, 0.22);
    padding: 2px 2px;
    border-radius: 12px;

    a {
      display: inline-block;
      text-align: center;
      padding: 4px 5px;
      font-size: 0.55em;
      border-radius: 10px;
      font-weight: bold;

      &:hover {
        text-decoration: none;
      }

      &.secondary {
        display: none;
      }

      .b-icon {
        font-size: 150%;
        margin-bottom: 2px;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      @media (min-width: 360px) {
        font-size: 0.65em;
      }

      @media (min-width: 525px) {
        font-size: 0.75em;
        padding: 4px 8px;

        &.secondary {
          display: inherit;
        }
      }
    }
  }
}
</style>